'use client'

import { RiReceiptLine, RiShoppingCartLine } from '@remixicon/react'
import { FloatButton, message } from 'antd'
import axios from 'axios'
import { Fragment, useContext, useState } from 'react'
import Lottie from 'react-lottie'

import useCartState from '@/hooks/context/useCartState'

import { IndexPageContext } from '@/components/Chatbot'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { cn } from '@/utils/clsx'

import Answer from './Answer/Answer'
import ReferencesModal from './Answer/ReferencesModal'
import Cart from '../Cart'
import lottieChatLoadingScreenAnimation from '../../../../public/lottieChatLoadingScreen.json'

import { Agent } from '@/types'
import { IMessage, IQuestion } from '@/types/chatbot'

interface AnswersProps {
  questions: IQuestion[]
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  hideHeader?: boolean
  adminChatbot?: boolean
  onSelectedDraft: (draft: string) => void
  agents?: Agent[]
  setQuestions: (questions: IQuestion[]) => void
}

const Answers: React.FC<AnswersProps> = ({
  questions,
  getAnswer,
  hideHeader,
  adminChatbot,
  onSelectedDraft,
  agents,
  setQuestions,
}) => {
  const { table, isCartOpen, setIsCartOpen } = useCartState()
  const isIndexPage = useContext(IndexPageContext)
  const [referencesQuestionInfo, setReferencesQuestionInfo] = useState<{
    documents: string[]
    googleDriveUrls: string[]
  }>({ documents: [], googleDriveUrls: [] })

  const updateQuestion = (question: IMessage) => {
    const updatedQuestions = questions.map((q) => {
      const updatedMessages = q.messages.map((msg) => {
        if (msg.logId === question.logId) {
          return question
        }
        return msg
      })
      return {
        ...q,
        messages: updatedMessages,
      }
    })
    setQuestions(updatedQuestions)
  }

  const sendEmail = async () => {
    await axios(`${API_URL}/cart/email`, {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        body: `Table ${table} requested a receipt.`,
      }),
    })
    message.success('Bar staff was notified of your request.')
  }

  return (
    <>
      <div
        id='chat-container'
        className={cn(
          'hide-scrollbar flex w-full flex-col overflow-hidden overflow-y-auto pb-24',
          !adminChatbot ? 'h-screen' : 'h-full',
          !hideHeader &&
            (isIndexPage
              ? 'items-center pt-20 lg:pt-32'
              : 'pt-16 sm:h-[40rem] sm:max-h-[80vh]')
        )}
      >
        {isCartOpen ? (
          <Cart />
        ) : table ? (
          <FloatButton.Group
            shape='square'
            className='!bottom-28 flex flex-col items-center !text-on-primary dark:!text-dark-on-primary'
          >
            <FloatButton
              type='primary'
              onClick={() => sendEmail()}
              icon={<RiReceiptLine className='size-4' />}
              tooltip='Receipt please!'
            />
            <FloatButton
              type='primary'
              onClick={() => setIsCartOpen(true)}
              icon={<RiShoppingCartLine className='size-4' />}
              shape='square'
              tooltip='Your order'
            />
          </FloatButton.Group>
        ) : null}
        {questions.length === 0 && (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        )}

        {questions.map(({ messages, question }, index1) =>
          messages.map((msg, index2) => (
            <Fragment key={`${index1}-${index2}`}>
              {msg.message !== '' && msg.message !== 'NO ANSWER' && (
                <Answer
                  question={question}
                  questionInfo={msg}
                  getAnswer={getAnswer}
                  onSelectedDraft={onSelectedDraft}
                  onReferencesClick={(documents, googleDriveUrls) => {
                    setReferencesQuestionInfo({
                      documents,
                      googleDriveUrls,
                    })
                  }}
                  isLastAnswer={
                    index1 === questions.length - 1 &&
                    index2 === messages.length - 1
                  }
                  agents={agents}
                  className={isIndexPage ? 'w-full sm:max-w-[60em]' : ''}
                  updateQuestion={updateQuestion}
                />
              )}
            </Fragment>
          ))
        )}
        <div id='last-answer' className='opacity-0'>
          a
        </div>
      </div>
      <ReferencesModal
        documents={referencesQuestionInfo.documents}
        googleDriveUrls={referencesQuestionInfo.googleDriveUrls}
      />
    </>
  )
}

export default Answers
