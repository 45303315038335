'use client'

import { TextField } from '@mui/material'
import { RiAttachment2, RiSendPlane2Line, RiStopLine } from '@remixicon/react'
import { Button, Tooltip, Upload, UploadFile } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React, {
  FormEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import PulseLoader from 'react-spinners/PulseLoader'

import useCartState from '@/hooks/context/useCartState'
import useThemeState from '@/hooks/context/useThemeState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { colors } from '@/branding-config'
import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { isCommand, uploadDocuments } from '@/service/Chatbot'
import { commands } from '@/utils'
import { cn } from '@/utils/clsx'
import { convertToFileList } from '@/utils/upload'

import InputFooter from './Input/InputFooter'
import SuggestedQuestions from './Output/SuggestedQuestions'
import SignUpWarning from './SignUpWarning'
import TableForm from './TableForm'

import { Agent, Settings } from '@/types'

interface InputProps {
  loading: boolean
  userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }
  setUserSettings: (userSettings: {
    useGpt4: boolean
    useDrafting: boolean
    useStreaming: boolean
  }) => void
  adminChatbot?: boolean
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string,
    isCommand?: boolean
  ) => void
  stopAnswering: () => void
  fileList: FileList | null
  setFileList: (fileList: FileList | null) => void
  dragging: boolean
  settings?: Settings
  showSuggestedQuestions: boolean
  agent: Agent
}

const QuestionInput: React.FC<InputProps> = ({
  getAnswer,
  stopAnswering,
  loading,
  userSettings,
  setUserSettings,
  adminChatbot,
  setFileList,
  fileList,
  settings,
  showSuggestedQuestions,
  dragging,
  agent,
}) => {
  const { theme } = useThemeState()
  const [showCommandPopup, setShowCommandPopup] = useState(false)
  const [currentCommandText, setCurrentCommandText] = useState('')
  const [commandSelected, setCommandSelected] = useState(false)
  const [filteredCommands, setFilteredCommands] = useState(commands)
  const [question, setQuestion] = useState('')
  const [files, setFiles] = useState<UploadFile[] | undefined>(undefined)
  const { t } = useTranslation()
  const { userTourRef1, userTourRef2 } = useTourState()
  const { user } = useAuth()
  const { settings: dbSettings } = useDbSettings()
  const [uploading, setUploading] = useState(false)
  const { selectedConversation } = useContext(DrawerContext)
  const { table } = useCartState()

  const handleUpload = async () => {
    if (files) {
      setUploading(true)
      await uploadDocuments(
        fileList,
        agent.id,
        t,
        setFileList,
        true,
        undefined,
        selectedConversation
      )
      setUploading(false)
    }
  }

  const askDomainQuestion = async (event: FormEvent) => {
    if (isDisabled()) {
      return
    }
    setQuestion('')
    event.preventDefault()
    getAnswer(true, question, undefined, commandSelected)
  }

  const doStopAnswering = (event: FormEvent) => {
    event.preventDefault()
    stopAnswering()
  }

  const emptyInput = question.length === 0

  const onQuestionInput = (event: FormEvent) => {
    setQuestion((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    if (!fileList) {
      setFiles(undefined)
    }
  }, [fileList])

  useEffect(() => {
    if (commandSelected && !question.startsWith(currentCommandText)) {
      setCurrentCommandText('')
      setCommandSelected(false)
      setFilteredCommands(commands)
    } else if (!commandSelected && isCommand(question)) {
      setCommandSelected(true)
      setCurrentCommandText(question.split(' ')[0] ?? '')
    }
  }, [question])

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !emptyInput) {
      askDomainQuestion(event)
    } else if (event.key === '/' && question.length <= 1) {
      setShowCommandPopup(true)
      setCurrentCommandText(question)
    } else if (
      event.key === 'Backspace' &&
      question.length > 0 &&
      isCommand(question)
    ) {
      setShowCommandPopup(true)
      const filtered = commands.filter((command) =>
        command.command.includes(question.toLowerCase())
      )
      setFilteredCommands(filtered)
    } else if (event.key === 'Backspace' && question.length === 0) {
      setShowCommandPopup(false)
    } else {
      if (showCommandPopup) {
        // Hide command popup
        if (event.key === ' ') {
          setShowCommandPopup(false)
        } else {
          // filter commands
          setCurrentCommandText(question)
          const filtered = commands.filter((command) =>
            command.command.includes(question.toLowerCase())
          )
          setFilteredCommands(filtered)
        }
      }
    }
  }

  const handleCommandClick = (command: string) => {
    setShowCommandPopup(false)
    setCommandSelected(true)
    setCurrentCommandText(command)
    setQuestion(command + ' ')

    // focus on input
    const input = document.getElementById('chat-input')
    input?.focus()
  }

  const isDisabled = () => {
    if (dbSettings?.requireSubscription) {
      return !user?.subscribed
    }

    if (dbSettings?.useDocumenso) {
      return !user?.signed
    }

    return false
  }

  if (!table) {
    return <TableForm />
  }

  return (
    <div className='absolute bottom-0 flex w-full flex-col'>
      {isDisabled() && <SignUpWarning />}
      {showSuggestedQuestions && !isDisabled() && (
        <SuggestedQuestions getAnswer={getAnswer} settings={settings} />
      )}
      {adminChatbot && showCommandPopup && (
        <div className='relative left-1/2 m-3 w-full max-w-fit -translate-x-[52%] rounded-lg border border-on-surface/50 bg-surface p-2 text-xs text-on-surface dark:border-dark-on-surface/50 dark:bg-dark-surface dark:text-dark-on-surface'>
          <div
            className='flex flex-col justify-items-start gap-2 overflow-y-auto'
            style={{ maxHeight: '20vh' }}
          >
            {filteredCommands.map((command) => (
              <div
                key={command.command}
                className='transition-colors[theme] cursor-pointer rounded-sm p-2 duration-300 ease-in-out hover:bg-on-surface/50 dark:bg-dark-on-surface/50'
                onClick={() => handleCommandClick(command.command)}
              >
                <div className='font-bold' style={{ textAlign: 'left' }}>
                  {command.command}
                </div>
                <div className='text-xs' style={{ textAlign: 'left' }}>
                  {command.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <form
        onSubmit={askDomainQuestion}
        className={`relative flex w-full flex-col items-center gap-1 bg-surface px-3 py-2 pt-3 backdrop-blur-md dark:bg-dark-surface ${isDisabled() ? 'cursor-not-allowed' : ''}`}
      >
        <Tooltip title={isDisabled() ? t('subscription-required') : ''}>
          <div
            className='relative flex w-full max-w-[1000px] items-center rounded-lg transition-all duration-300 ease-in-out'
            style={{
              border: `1px dashed ${dragging ? colors[theme].on.background : 'transparent'}`,
            }}
          >
            <Dragger
              beforeUpload={() => false}
              onChange={(e) => {
                setFileList(convertToFileList(e.fileList))
                setFiles(e.fileList)
              }}
              multiple
              accept='.doc,.docx,.pdf,.csv'
              className='w-full !p-0'
              openFileDialogOnClick={false}
              fileList={files}
              disabled={loading || isDisabled()}
            >
              <div className='flex items-center rounded-lg bg-background dark:bg-dark-background'>
                <TextField
                  ref={userTourRef1}
                  id='chat-input'
                  maxRows={5}
                  placeholder={t('input-placeholder')}
                  label={
                    commandSelected && adminChatbot ? 'Command selected' : ''
                  }
                  autoFocus
                  value={question}
                  disabled={loading || isDisabled()}
                  onInput={onQuestionInput}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                    handleKeyPress(event)
                  }
                  multiline
                  size='small'
                  inputProps={{
                    style: {
                      color: colors[theme].on.background,
                      WebkitTextFillColor: 'unset',
                    } as React.CSSProperties,
                  }}
                  className='noBorder w-full rounded-lg border-0 bg-background dark:bg-dark-background'
                />
                <div className='flex items-center gap-2 px-3 transition-all duration-300 ease-in-out'>
                  {!loading ? (
                    <>
                      <Upload
                        multiple
                        accept='.doc,.docx,.pdf,.csv'
                        onChange={(e) => {
                          setFileList(convertToFileList(e.fileList))
                          setFiles(e.fileList)
                        }}
                        disabled={loading || isDisabled()}
                        showUploadList={false}
                        className='flex items-center'
                      >
                        <div ref={userTourRef2}>
                          <RiAttachment2 className='text-on-background/40 outline-on-background/30 hover:text-on-background/80 dark:text-dark-on-background/40 dark:outline-dark-on-background/30 hover:dark:text-dark-on-background/80' />
                        </div>
                      </Upload>

                      <RiSendPlane2Line
                        onClick={(event) => {
                          !emptyInput && askDomainQuestion(event)
                        }}
                        className={cn(
                          'outline-on-background/30',
                          emptyInput
                            ? 'text-on-background/40 dark:text-dark-on-background/40 outline-on-background/30 dark:outline-dark-on-background/30'
                            : 'cursor-pointer text-on-background dark:text-dark-on-background hover:text-on-background/80 hover:dark:text-dark-on-background/80'
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <PulseLoader
                        color={colors[theme].on.background}
                        loading={loading}
                        speedMultiplier={0.5}
                        size={8}
                      />
                      <RiStopLine
                        onClick={doStopAnswering}
                        className='cursor-pointer text-on-background hover:text-on-background/80 dark:text-dark-on-background hover:dark:text-dark-on-background/80'
                      />
                    </>
                  )}
                </div>
              </div>
            </Dragger>
          </div>
        </Tooltip>
        {fileList && fileList.length > 0 && (
          <Button onClick={handleUpload} disabled={uploading}>
            {t('upload')}
          </Button>
        )}
        <InputFooter
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          adminChatbot={adminChatbot}
          agent={agent}
        />
      </form>
    </div>
  )
}

export default QuestionInput
