'use client'

import { Button, Divider, Drawer, message } from 'antd'
import axios from 'axios'

import useCartState from '@/hooks/context/useCartState'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import CartItem from './CartItem'

const Cart: React.FC = () => {
  const { cart, isCartOpen, setIsCartOpen, table } = useCartState()

  const sendEmail = async () => {
    await axios(`${API_URL}/cart/email`, {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        body: `Table ${table} just sent an order.\n\n${cart.map((item) => `${item.quantity}x ${item.name} (Total price: €${item.quantity * item.price})`).join('\n')}`,
      }),
    })
    message.success('Bar staff was notified about your order.')
  }

  return (
    <Drawer
      title={`Table ${table}`}
      placement='right'
      onClose={() => setIsCartOpen(false)}
      open={isCartOpen}
      getContainer={false}
      className='text-left'
      extra={
        <Button type='primary' onClick={sendEmail} disabled={cart.length === 0}>
          Order
        </Button>
      }
      afterOpenChange={(open) => setIsCartOpen(open)}
    >
      <div className='flex h-full flex-col justify-between'>
        <div className='flex flex-col gap-2'>
          {cart.map((item, index) => (
            <CartItem item={item} key={index} inCart />
          ))}
        </div>
        <div className='flex flex-col'>
          <Divider />
          <div className='flex items-center justify-between'>
            <div className='text-lg'>Total</div>
            <div className='text-lg font-bold'>
              €
              {new Intl.NumberFormat().format(
                cart.reduce((acc, item) => acc + item.price * item.quantity, 0)
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default Cart
