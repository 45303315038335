import { useContext } from 'react'

import { CartContext } from '@/context/cart/cart.context'

const useCartState = () => {
  const cartContext = useContext(CartContext)
  if (!Object.keys(CartContext).length) {
    throw new Error('CartContext must be used within its provider')
  }
  return cartContext
}

export default useCartState
