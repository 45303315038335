'use client'

import { useEffect, useState } from 'react'

import useCartState from '@/hooks/context/useCartState'

import { markdown } from '@/utils'

import CartItem from './CartItem'

import { Product } from '@/types/cart'

interface SuggestedItemsProps {
  message?: string
}

const SuggestedItems: React.FC<SuggestedItemsProps> = ({ message }) => {
  const { cart } = useCartState()
  const [text, setText] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {
    if (message) {
      try {
        const json = JSON.parse(message)
        setText(json.text)
        setItems(json.items)
      } catch (e) {
        setText(
          'Sorry, I could not understand that. Could you please repeat your request?'
        )
      }
    }
  }, [message])

  return (
    <div className='flex flex-col gap-3'>
      {markdown(text)}
      {items.map((suggestedItem: Product, index) => {
        const itemInCart = cart.find((item) => item.name === suggestedItem.name)
        return <CartItem item={itemInCart ?? suggestedItem} key={index} />
      })}
    </div>
  )
}

export default SuggestedItems
