'use client'

import { Button, Form, Input } from 'antd'
import { useRouter } from 'next/navigation'

const TableForm: React.FC = () => {
  const [form] = Form.useForm()
  const router = useRouter()

  const onFinish = async () => {
    router.push(`?table=${form.getFieldValue('tableId')}`)
  }

  return (
    <div className='absolute bottom-0 flex w-full flex-col'>
      <div className='relative left-1/2 m-3 w-full max-w-fit -translate-x-[52%] rounded-lg bg-surface p-2 text-left text-on-surface dark:bg-dark-surface dark:text-dark-on-surface'>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete='off'
          className='flex flex-col text-left'
          layout='vertical'
        >
          <Form.Item
            label='Enter your table ID'
            name='tableId'
            rules={[{ type: 'string', required: true }]}
          >
            <Input placeholder='Table ID' />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' className='w-full'>
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default TableForm
