'use client'

import { RiAddLine, RiSubtractLine } from '@remixicon/react'
import { Button, Card } from 'antd'
import { useEffect, useState } from 'react'

import useCartState from '@/hooks/context/useCartState'

import { cn } from '@/utils/clsx'

import { Product } from '@/types/cart'

interface CartItemProps {
  item: Product
  inCart?: boolean
}

const CartItem: React.FC<CartItemProps> = ({ item, inCart }) => {
  const { cart, updateQuantity } = useCartState()
  const [quantity, setQuantity] = useState(item.quantity)

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  return (
    <Card type='inner'>
      <div className='flex flex-col justify-between gap-4 sm:flex-row'>
        <div className='flex flex-col justify-between text-left'>
          <div className='text-base'>{item.name}</div>
          <div className='text-sm opacity-70'>
            {inCart ? item.short_description : item.long_description}
          </div>
          <div className='text-lg font-bold'>
            €{new Intl.NumberFormat().format(item.price)}
          </div>
        </div>
        <div className='flex w-fit flex-col gap-2 self-end sm:self-auto'>
          <div
            className={cn(
              'flex w-full flex-col justify-between rounded-md border border-on-surface/30 text-center dark:border-dark-on-surface/30 h-fit',
              inCart ? '' : 'flex-row-reverse'
            )}
          >
            <Button
              onClick={() =>
                inCart
                  ? updateQuantity({ ...item, quantity: quantity + 1 })
                  : setQuantity(quantity + 1)
              }
              icon={<RiAddLine className='size-5' />}
              size='small'
              type='text'
            />
            <div>{quantity}</div>
            <Button
              onClick={() =>
                inCart
                  ? updateQuantity({ ...item, quantity: quantity - 1 })
                  : setQuantity(quantity - 1)
              }
              icon={<RiSubtractLine className='size-5' />}
              size='small'
              type='text'
              disabled={quantity === 0}
            />
          </div>
          {!inCart && (
            <Button
              className='w-28'
              onClick={() => updateQuantity({ ...item, quantity })}
              disabled={cart.some(
                (cartItem) =>
                  cartItem.name === item.name && cartItem.quantity === quantity
              )}
            >
              {cart.some(
                (cartItem) =>
                  cartItem.name === item.name && cartItem.quantity === quantity
              )
                ? 'Item added'
                : cart.some((cartItem) => cartItem.name === item.name)
                  ? 'Update order'
                  : 'Add to order'}
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

export default CartItem
